@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/Spartan/Spartan-Thin.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/Spartan/Spartan-ExtraLight.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Spartan/Spartan-Light.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Spartan/Spartan-Regular.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Spartan/Spartan-Medium.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Spartan/Spartan-SemiBold.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Spartan/Spartan-Bold.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/Spartan/Spartan-ExtraBold.ttf');
}

@font-face {
    font-family: Spartan;
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Spartan/Spartan-Black.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf');
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Spartan", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
    color: #333;
}

p {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, sans-serif;
    color: #333;
}

.App {
    text-align: center;
}

.logo {
    height: 40vmin;
    pointer-events: none;
}

.header {
    background-color: #fff;
    min-height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}
